<template>
    <h2>Brand Details</h2>
</template>
<script>
    export default {
        name: 'brandDetails',
        props: {
            msg: String
        }
    }
</script>